<template>
  <div class="anipang2 wrapper">
    <div id="intro"
         class="intro section">
      <div class="container">
        <br><br><br><br>
        <h2 class="section-title">
          <img :src="sections.intro.title" alt="간단하고 빠르게 즐기는 클래식 3매치, 애니팡2"/>
        </h2>
        <div class="md-layout">
          <div class="md-layout-item md-size-50 md-small-size-100 text-center">
            <img :src="sections.intro.phone" alt="phone">
          </div>
          <div class="section-contents md-layout-item md-size-50 text-center md-small-size-100 mx-auto">
            <div class="section-description">
              <h3 class="game-description">
                플레이는 더 화려하고 빠르게!<br>
                조작은 더 심플하고 간편하게!<br>
                <br>
                세 번의 리마스터로 더욱 완성된
                애니팡2를 바로 만나보세요.
              </h3>
              <br><br><br>
              <div class="game-tag">
                <span>#끝없는_퍼즐모험</span><br>
                <span>#두개의_월드</span><br>
                <span>#7천개의_스테이지</span>
              </div>
            </div>
          </div>
        </div>
        <br>
        <br>
        <div class="down-links md-layout">
          <a href="https://anipang2.onelink.me/KXv0?af_ios_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.sundaytoz.mobile.anipang2.google.kakao.service&af_web_dp=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.sundaytoz.mobile.anipang2.google.kakao.service&af_xp=custom&pid=Brandpage&c=2212_AP2_KR_APS_BR_PMO_BRANDPAGE_SUB&af_dp=AFstzkakaoanipang2%3A%2F%2F" target="_blank" class="md-layout-item md-size-50 md-small-size-100">
            <img :src="sections.intro.google_bt" alt="google_btn">
          </a>
          <a href="https://anipang2.onelink.me/KXv0?af_android_url=https%3A%2F%2Fapps.apple.com%2Fkr%2Fapp%2Fid660164846&af_web_dp=https%3A%2F%2Fapps.apple.com%2Fkr%2Fapp%2Fid660164846&af_xp=custom&pid=Brandpage&c=2212_AP2_KR_APS_BR_PMO_BRANDPAGE_SUB&af_dp=AFstzkakaoanipang2%3A%2F%2F" target="_blank" class="md-layout-item md-size-50 md-small-size-100">
            <img :src="sections.intro.apple_bt" alt="app_btn">
          </a>
        </div>
        <br><br><br>
      </div>
    </div>


  </div>
</template>

<script>

  export default {
    bodyClass: "anipang2",
    created() {},
    computed: {},
    data() {
      return {
        sections: {
          intro: {
            title: require("@/assets/img/pages/sub_page_a2_main_title.png"),
            phone: require("@/assets/img/pages/sub_page_a2_phone.png"),
            apple_bt: require("@/assets/img/pages/apple_bt.png"),
            google_bt: require("@/assets/img/pages/google_bt.png"),
          },
        },
      };
    },
  };
</script>

<style lang="scss">
  .anipang2 {
    .intro {
      background: rgb(236,248,255);
      background: linear-gradient(135deg, rgba(230,222,255,1) 0%, rgba(236,248,255,1) 100%);

      .container {
        .section-title {
          text-align: center;
        }
        .section-contents {
          padding-left: 30px !important;
          padding-right: 30px !important;
          display: flex;
          flex-wrap: wrap;
          align-content: center;

          .section-description {
            -webkit-font-smoothing: antialiased;
            text-align: left;

            .game-title{
              color: #2b55a0;
            }
            .game-description{
              color: #2b55a0;
            }
            .game-tag {
              span {
                color: #2b55a0;
                border-radius: 100px;
                background-color: white;
                border: 1px solid #2b55a0;
                padding: 10px 15px;
                margin: 5px 5px 5px 0;
                display: inline-block;
                font-size: 23px;
                box-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
              }
            }
          }
        }

        .down-links {
          margin: 0 auto;
          max-width: 600px;

          .md-layout-item {
            text-align: center;
          }
        }
      }
    }
  }
</style>